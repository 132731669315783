<template>
  <Confirm
    :value="value"
    icon="mdi-cellphone-link"
    icon-color="secondary"
    width="60vw"
    :has-footer="false"
    @input="handleInput"
  >
    <template #title>Upload files from another device</template>

    <template #default>
      <div id="uploadQR" class="q-py-md row qr-content">
        <FormFieldLabel
          label="Quickly upload files from another device by scanning a QR code. Scan it with the other device, and select the files you wish to upload. This seamless method ensures a fast and secure file transfer without the need for cables or manual file sharing."
          transform=""
        />

        <div :class="[{ imageIconContainer: timeOut }, 'col-12']">
          <img :src="qrImg" :class="[{ imgExpired: timeOut }, 'imgCenter']" />
          <BaseIcon
            v-if="timeOut"
            inherit-color
            name="mdi-reload"
            :class="[{ expired: timeOut }, 'info-icon']"
            @click="creatNew"
          />
        </div>

        <div class="col-12 text-center q-mb-sm text-secondary">
          QR will be expired in <span style="color: red">{{ remainTime }}</span>
        </div>

        <div class="row qr-info">
          <BaseIcon
            inherit-color
            name="eva-alert-circle-outline"
            class="info-icon"
          />
          <p>Keep the form open while uploading files.</p>
        </div>
      </div>
    </template>
  </Confirm>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import Confirm from "@/components/common/popup/Confirm.vue";
import { repository } from "@/api/factory.js";
export default {
  name: "GenerateQr",

  components: { FormFieldLabel, Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    repositoryId: {
      type: Number,
      default: 0,
    },
    fieldId: {
      type: String,
      default: "",
    },
    fieldName: {
      type: String,
      default: "",
    },
    isFileUploaded: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      default: () => {},
    },

    formFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      qrImg: "",
      remainTime: "03:00",
      uniqueId: 0,
      forseExpire: false,
      timeOut: true,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (this.value) {
          this.getQr();
        }
      },
    },
  },

  methods: {
    handleInput() {
      this.forseExpire = true;
      this.$emit("input", this.uniqueId);
      this.$emit("expired", this.uniqueId, this.timeOut);
      // console.log(this.$store.state.session, "this.$store.state.session");
    },
    creatNew() {
      this.timeOut = false;
      this.getQr();
    },
    async getQr() {
      let origin = location.origin;
      if (origin === "http://localhost:8080") {
        origin = "https//trial.ezofis.com";
      } else if (origin === "http://52.172.32.88") {
        origin = "http://52.172.32.88/app";
      } else if (origin === "https://demo.ezofis.com") {
        origin = "https://demo.ezofis.com/app";
      }
      let serverTime = new Date().getTime();
      let input = {
        url: origin + "/mobileUpload",
        jsonData: JSON.stringify([
          {
            repositoryId: this.repositoryId,
            fieldId: this.fieldId,
            fieldName: this.fieldName,
            time: serverTime,
            expired: false,
            settings: this.settings,
            formFields: this.formFields,
          },
        ]),
        status: 0,
      };

      // console.log(input, "input");
      const { error, payload } = await repository.getQrcode(input);
      // console.log(error, payload, "error, payload");
      if (error) {
        return;
      }
      this.timer = true;
      const { id, output } = JSON.parse(payload.data);
      this.qrImg = "data:image/png;base64," + output;
      // console.log(this.qrImg, "this.qrImg", id);
      this.forseExpire = false;
      this.timeOut = false;
      this.$emit("checkUploadId", id);
      this.isExpired(id, serverTime);
      this.uniqueId = id;
    },

    isExpired(id, serverTime) {
      this.remainingTime = 180;
      const countdown = setInterval(() => {
        let currentTime = new Date().getTime();
        let timeDifference = (currentTime - serverTime) / 1000;

        if (this.forseExpire) {
          clearInterval(countdown);
          this.remainTime = "00:00";
          return;
        }

        if (timeDifference >= 180) {
          clearInterval(countdown);
          this.remainTime = "00:00";
          this.timeOut = true;
          this.$emit("expired", id, this.timeOut);
          return;
        } else if (this.isFileUploaded) {
          clearInterval(countdown);
          this.remainTime = "00:00";
          // this.$emit("expired", id);
          return;
        }
        this.remainingTime = 180 - Math.floor(timeDifference);

        let minutes = Math.floor(this.remainingTime / 60);
        let seconds = this.remainingTime % 60;

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.remainTime = `${minutes}:${seconds}`;

        if (this.remainingTime > 0) {
          // console.log("called");
        } else {
          clearInterval(countdown);
          this.remainTime = "00:00";
          this.$emit("expired", id, this.timeOut);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
#uploadQR {
  .qr-content {
    justify-content: center;
  }
  #confirm .footer {
    display: none !important;
  }
  .qr-info {
    width: 100%;
    color: red;
  }
  .qr-info p {
    margin: 0px;
  }
  .info-icon {
    margin-right: 3px;
  }
  .imgCenter {
    width: 60%;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .imageIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .imgExpired {
    opacity: 0.3;
  }
  .expired {
    font-size: 40px !important;
    position: absolute;
    color: #18adbf;
    opacity: 1 !important;
  }
}
</style>
